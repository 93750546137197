<template>
  <div class="flex items-start w-full gap-4 p-2 py-3 pl-3 border rounded-xl border-primary-700">
    <div class="text-xl shrink-0 ">
      <FadedIcon
        :icon-name="chooseIcon"
        :color="choseColor"
      />
    </div>
    <h4 class="w-full overflow-x-auto text-sm font-medium text-gray-700 mt-0.5">
      {{ text }}
    </h4>
    <button
      v-if="!isSubmitting"
      class="flex-shrink-0 w-6 p-1 text-gray-500 transition-all duration-100 transform bg-white rounded-full active:bg-gray-200 focus:outline-none focus-visible:ring-1 focus-visible:ring-primary-700 focus-visible:text-primary-700 hover:-rotate-[20deg] focus-visible:-rotate-[20deg] hover:text-primary-700 active:bg-white"
      @click="emit('remove', id, type)"
    >
      <IconResolver icon-name="trash" />
      <span class="sr-only">Remove {{ text }}</span>
    </button>
    <div
      v-else
      class="w-6 shrink-0"
      :class="[
        status === 'parsed' && 'text-success-700',
        status === 'failed' && 'text-error-600',
        status === 'waiting' && 'text-gray-700',
        status === 'parsing' && 'text-gray-700'
      ]"
    >
      <IconResolver
        :icon-name="chooseStatusIcon"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconNames } from '~/types'
type IEmits = {
  remove: [identifier:string, type:string]
}
const emit = defineEmits<IEmits>();

type IProps = {
  id: string,
  text: string,
  type: string,
  isSubmitting: boolean,
  status: 'failed' | 'waiting' | 'parsed' | 'parsing'
}
const props = defineProps<IProps>()

const chooseIcon = computed(() => {
  if(props.type === 'file') return 'file'
  if(props.type === 'website') return 'link'
  if(props.type === 'file_behind_url') {
    return predicateUrlType(props.text)
  }
  return props.type as IconNames
})
const chooseStatusIcon = computed<IconNames>(() => {
  if(props.status === 'parsed') return 'verified'
  if(props.status === 'failed') return 'x'
  return 'loading'
})
const choseColor = computed(() => {
  if(props.status === 'parsed') return 'success'
  if(props.status === 'failed') return 'error'
  return 'primary'
})

const predicateUrlType = (url: string | undefined) => {
  // Assume website if no url is present — just to fail gracefully
  if (!url) return "website";
  // Assume docSend if url includes the word docsend
  if (url.toLowerCase().includes("docsend")) {
    return "docSend";
  } else if (url.toLowerCase().includes("pitch.com")) {
    return "pitch";
  } else if (url.toLowerCase().includes("dropbox.com")) {
    return "dropbox";
  } else if (url.toLowerCase().includes("google.com")) {
    return "google";
  } else if (url.toLowerCase().includes("hubs.ly")) {
    return "hubspot";
  } else {
    // All other urls are assumed to be websites
    return "website";
  }
};
</script>