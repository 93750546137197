import type { ParsedDecksResponse } from '@/types'
const useUserSettingsCookie = useCookie(
  'userSettings',
  {
    default: () => {
      return {
        slimKanban: false,
        selectedView: 'list',
        tableColumns: [] as { key: keyof ParsedDecksResponse, label: string }[],
      }
    }
  }
)

export default useUserSettingsCookie