<template>
  <UForm
    :schema="schema"
    :state="state"
    @submit="handleAddUrl"
    class="flex flex-col gap-2"
  >
    <UFormGroup name="url" label="Website url">
      <UInput
        v-model="state.url"
        id="url"
        name="url"
      />
    </UFormGroup>

    <div class="flex justify-end">
      <UButton type="submit" color="gray" label="Add to queue" />
    </div>
  </UForm>
</template>

<script lang="ts" setup>
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";
import { urlSchema } from "@/utils/validations";
const emit = defineEmits(["update"]);

// Define the schema
const schema = z.object({
  url: urlSchema.or(z.literal("")),
});

// Define type from schema
type Schema = z.output<typeof schema>;

// Initialize form state
const state = ref<Schema>({
  url: "",
});

// Handle form submission
const handleAddUrl = (event: FormSubmitEvent<Schema>) => {
  if (event.data.url === "") return;
  emit("update", event.data);
  state.value.url = "";
};
</script>
