import type { ErrorCodesFromApi } from '@/types'
export const generateErrorMessageFromApi = (errorCode: ErrorCodesFromApi | undefined) => {
  let message = ''
  if(!errorCode) message = 'Something went wrong.'
  switch (errorCode) {
    case 'SUBSCRIPTION_EXPIRED':
      message = 'You\'ve reached your account\'s limit. To continue using our service, please write to contact@deckmatch.com'
      break;
      
    case 'INVALID_PDF_CONTENT':
      message = 'The file you uploaded doesn\'t seem to be a valid PDF. Please check your file and try uploading again.'
      break;

    case 'DECK_REQUIRED':
      message = 'You need to upload a file (pdf/ppt/keynote) or submit a valid link (docsend or company url) in order to continue.'
      break;

    case 'DOCSEND_EMAIL_AUTH_REQUIRED':
      message = 'A link to access the DocSend PDF has been sent to your email. Please copy that link and run it.'
      break;

    case 'DOCSEND_PASSWORD_AUTH_REQUIRED':
      message = 'This DocSend link is password protected. Please try again and add the password in the "password" field.'
      break;

    case 'DOCSEND_URL_NOT_FOUND':
      message = 'The DocSend URL you provided could not be found. Please check the URL and try again.'
      break;
    case 'DOCSEND_FILE_NOT_AVAILABLE':
      message = 'Please ensure you\'re using the original DocSend URL to allow us to access the file.'
      break;
    case 'INACCESSIBLE_URL':
      message = 'Website not reachable, either it is down or has anti scraping measures in place.'
      break;
    case 'ATTACHMENT_URL_NOT_SUPPORTED':
      message = 'Something happened while trying to access the attachment URL. Please try to manually upload the file.'
      break;

    case 'UNSUPPORTED_FILE_CONTENTS':
      message = 'Something happened while trying to process the attachment URL. Please try again or manually upload the file.'
      break;

    case 'GDRIVE_URL_NOT_SUPPORTED':
      message = 'We only support google drive links that are shared with anyone with the link. Please make sure your link is shared with anyone with the link. If you are still facing issues, please try uploading the file directly.'
      break;

    case 'GDOCS_URL_NOT_SUPPORTED':
      message = 'We only support google docs links that are shared with anyone with the link. Please make sure your link is shared with anyone with the link. If you are still facing issues, please try uploading the file directly.'
      break;

    case 'GOOGLE_LINK_NOT_PUBLIC':
      // the file require authorization to access
      message = 'We currently don\'t support files that require authorization to access. Please make sure the file is shared with anyone with the link. Or try uploading the file directly.'
      break;

    case 'WEBSITE_SCRAPER_ERROR':
      message = 'Failed to scrape the pitch deck from the website. Please try again or upload the file directly.'
      break;

    case 'CLOUDCONVERT_ERROR':
      message = 'Failed to convert url to PDF. Please try again or upload the file directly.'
      break;

    case 'ADMIN_REQUIRED':
      message = 'This action is restricted to administrators only. Please contact your Deckmatch representative for assistance.'
      break;
    case 'KANBAN_STAGE_NOT_EXISTS':
      message = 'The stage you are trying to update does not exist. Please refresh the page and try again.'
      break;
    case 'KANBAN_STAGE_DUPLICATE':
      message = 'A stage with the same name already exists. Please try a different name or change the existing stage name.'
      break;

    case 'PARSED_OBJECT_NOT_FOUND':
      message = 'We could not find what you are trying to update. Please refresh the page and try again.'
      break;

    case 'ATTACHMENT_URL_NOT_SUPPROTED':
      message = 'Decks hosted on this domain are not supported yet. Please try uploading the file directly.'
      break;

    case 'DECK_EXISTS':
      message = 'We have stopped processing this deck as it has already been uploaded by you or someone on your team.'
      break;

    case 'RATE_LIMIT_EXCEEDED':
      message = 'You have exceeded the rate limit for your account. Reach out to contact@deckmatch.com to increase your limit.'
      break;

    case 'POSSIBLE_ATTACHMENT_URL':
      message = 'This URL might be a deck url. Please try use the "Deck Url" field to upload the deck.'
      break;

    default:
      message = 'Something went wrong.'
      break;
  }
  return message
}


