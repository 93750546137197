import { z } from "zod";

export const urlSchema = z.string()
  .min(3, "URL must be at least 3 characters")
  .refine((val) => {
    if (val === "") return true; // Allow empty string
    
    // Remove protocol if present
    const domainOnly = val.replace(/^https?:\/\//, '');
    
    // Basic domain validation pattern
    const domainRegex = /^[a-zA-Z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(\/[^\s]*)?$/;
    return domainRegex.test(domainOnly);
  }, "Please enter a valid domain name")
